<template>
  <div>
    <h3>Referral User</h3>
    <div class="go-back">
      <div class="go-back-button">
        <router-link to="/admin/referrals">
          <el-button size="small" icon="el-icon-arrow-left">Go Back</el-button>
        </router-link>
      </div>
      <div class="search">
        <el-input placeholder="Search" v-model="search" clearable></el-input>
      </div>
    </div>
    <el-table :data="referralUser">
      <el-table-column label="Name" prop="name">
        <template slot-scope="scope">
          {{ scope.row.user.name }}
        </template>
      </el-table-column>
      <el-table-column label="Email" prop="user.email"> </el-table-column>
      <el-table-column label="Phone" prop="phone">
        <template slot-scope="scope">
          {{ scope.row.user.phone }}
        </template>
      </el-table-column>

      <el-table-column label="Amount" prop="amount"></el-table-column>
      <el-table-column
        label="Referral Type"
        prop="amount_type"
      ></el-table-column>
      <el-table-column label="Date">
        <template slot-scope="scope">
          {{ moment(scope.row.created_at).format("ll hh:mm:ss A") }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import moment from "moment";
  export default {
    data() {
      return {
        tableLoading: false,
        search: "",
      };
    },
    computed: {
      referralUser() {
        return store.getters["admin/referrals/getReferralDataUser"].filter(
          (data) =>
            data.user.name.toLowerCase().includes(this.search.toLowerCase()) ||
            data.user.phone == this.search
        );
      },
    },
    created() {
      this.tableLoading = true;
      store
        .dispatch("admin/referrals/getReferralUser", {
          referrer_id: this.$route.params.id,
        })
        .then((data) => {})
        .catch(() => {
          this.$message.error("Error fetching user referrals");
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  };
</script>
<style scoped>
  .go-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }

  .search {
    width: 18rem;
  }
</style>
